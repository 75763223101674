<style lang="less" scoped>
.master-view {
  width: 100%;
  height: 100vh;
  height: calc(100vh - 120px);
  background: #070911;
  padding-top: 120px;
  &-third {
    width: 100%;
    height: calc(100vh - 220px);
    background: #0f1118;
    overflow: scroll;
    &-container {
      width: 76.7%;
      max-width: 1104px;
      color: rgba(255, 255, 255, 1);
      margin: 0 auto;
      &-main-item {
        width: 100%;
        height: 70px;
        line-height: 70px;
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 400;
        p {
          margin: 0;
        }
        &:hover {
          cursor: pointer;
          background: rgba(7, 9, 17, 1);
        }
      }
    }
  }
}
</style>

<template>
  <div class="master-view">
    <div class="master-view-third">
      <div class="master-view-third-container">
        <div class="master-view-third-container-main"
             v-if="articleList.length > 0">
          <div class="master-view-third-container-main-item"
               v-for="(item, index) in articleList"
               :key="index"
               @click="linkToNews(item)">
            <p>
              {{ item.title }}
            </p>
            <p>
              {{ item.modified.split(' ')[0] }} >
            </p>
          </div>
        </div>
      </div>
    </div>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import CommonFooter from '../components/common-footer';

export default {
  name: 'master-view',
  data () {
    return {
      articleList: []
    }
  },
  created () {
    this.$api.getArticles({
      category: 'news'
    }).then(res => {
      this.articleList = res.articles;
    })
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    },
    linkToNews (item) {
      if (item.source === 0) {
        this.$router.push({
          name: 'news',
          params: item
        })
      } else {
        this.linkTo(item.link, '_blank');
      }
    }
  },
  components: {
    CommonFooter
  }
}
</script>
